import { background } from "./background";
import { border } from "./border";
import { color } from "./color";
import { display } from "./display";
import { font } from "./font";
import { flexChild, flexParent } from "./flexbox";
import { gridParent, gridChild } from "./grid";
import { height } from "./height";
import { margin } from "./margin";
import { objectFit } from "./object-fit";
import { overflow } from "./overflow";
import { padding } from "./padding";
import { position } from "./position";
import { text } from "./text";
import { width } from "./width";

export * from "./utilities";
export * from "./theme";
export const system = {
  background,
  border,
  color,
  display,
  font,
  "flex-child": flexChild,
  "flex-parent": flexParent,
  "grid-child": gridChild,
  "grid-parent": gridParent,
  height,
  margin,
  "object-fit": objectFit,
  overflow,
  padding,
  position,
  text,
  width,
};
export { GlobalStyles } from "./globals/reset";
