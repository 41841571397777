import styled from "styled-components";
import { applyStyleProps } from "../system";

export const Image = styled.img`
  ${applyStyleProps({
  system: [
    "display",
    "flex-child",
    "height",
    "grid-child",
    "margin",
    "object-fit",
    "width",
    "z-index",
  ],
})}
`;
