import { css } from "styled-components";
import { theme } from ".";

export const padding = {
  $padding: (value) =>
    css`
      padding: ${theme.spacing(value)};
    `,
  $paddingTop: (value) =>
    css`
      padding-top: ${theme.spacing(value)};
    `,
  $paddingBottom: (value) =>
    css`
      padding-bottom: ${theme.spacing(value)};
    `,
  $paddingLeft: (value) =>
    css`
      padding-left: ${theme.spacing(value)};
    `,
  $paddingRight: (value) =>
    css`
      padding-right: ${theme.spacing(value)};
    `,
  $paddingX: (value) =>
    css`
      padding-left: ${theme.spacing(value)};
      padding-right: ${theme.spacing(value)};
    `,
  $paddingY: (value) =>
    css`
      padding-top: ${theme.spacing(value)};
      padding-bottom: ${theme.spacing(value)};
    `,
};
