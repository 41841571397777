import { css } from "styled-components";

export const overflow = {
  $overflow: (value) => css`
    overflow: ${value};
  `,
  $overflowX: (value) => css`
    overflow: ${value};
  `,
  $overflowY: (value) => css`
    overflow: ${value};
  `,
};
