import React, { forwardRef } from "react";
import styled, { css } from "styled-components";
import { applyStyleProps } from "../system";

const sizes = {
  small: 16,
  medium: 24,
  large: 32,
};

const $size = (value) => {
  const sizeToApply = sizes[value] || sizes.medium;

  return css`
    height: ${sizeToApply}px;
    width: ${sizeToApply}px;
  `;
};

const Svg = styled.svg`
  fill: currentColor;
  flex: none;

  ${applyStyleProps({
    custom: { $size },
    system: ["display", "margin", "color"],
  })}
`;

export const Icon = forwardRef(({ src, $size, ...rest }, ref) => {
  if (src) {
    return (
      <Svg
        ref={ref}
        $size={$size}
        viewBox="0 0 32 32"
        dangerouslySetInnerHTML={{ __html: src }}
        {...rest}
      />
    );
  }

  return null;
});

Icon.defaultProps = {
  "aria-hidden": true,
};
