import styled from "styled-components";
import { applyStyleProps } from "../system";

export const Flex = styled.div`
  display: flex;

  ${applyStyleProps({
    system: ["height", "flex-parent", "flex-child", "margin", "width"],
  })}
`;
