import React from "react";
import styled from "styled-components";

import { Container, Text } from "../../../components";
import { theme } from "../../../system";

const Link = styled.a`
  color: ${theme.color("grey2")};
  display: block;
  font-family: "Montserrat", sans-serif;
  font-size: 48px;
  font-weight: 300;
  line-height: 1;
  max-width: 8em;
  outline: 0;
  position: relative;
  text-decoration: none;
  text-transform: uppercase;
  transition: 0.15s;

  ${Text} {
    transition: 0.125s;
  }

  &:hover {
    color: ${theme.color("primary.light")};

    ${Text} {
      color: ${theme.color("primary.light")};
    }
  }
`;

export const Header = ({ className, ...rest }) => (
  <Container $paddingX={5} $paddingY={{ xs: 6, lg: 8 }} $width="100%" {...rest}>
    <Link href="/" title="Return to the homepage.">
      <Text $fontWeight="900" $color="grey1">
        Greenwich music
      </Text>{" "}
      ltd
    </Link>
    <Text $textTransform="uppercase" $fontSize="small">
      We’re here to find the right music for you
    </Text>
  </Container>
);
