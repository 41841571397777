import { css } from "styled-components";
import { theme } from ".";

export const font = {
  $fontSize: (value) => css`
    font-size: ${theme.typography[value].fontSize};
    line-height: ${theme.typography[value].lineHeight};
  `,
  $fontFamily: (value) => css`
    font-family: ${theme.fontFamily[value]};
  `,
  $fontWeight: (value) => css`
    font-weight: ${value};
  `,
  $fontStyle: (value) => css`
    font-style: ${value};
  `,
};
