import React, {
  useEffect,
  useRef,
  forwardRef,
  useImperativeHandle,
} from "react";
import { createPortal } from "react-dom";
import { motion } from "framer-motion";
import styled from "styled-components";

import { IconButton, iconClose } from "../../components";
import { OverlayContext } from "./context";
import { tabLoop } from "./tab-loop";
import { applyStyleProps, theme } from "../../system";

const containerVariants = {
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 1,
      when: "beforeChildren",
      staggerChildren: 0.2,
    },
  },
  hidden: { opacity: 0, y: -20 },
};

const Wrapper = styled.div`
  background-color: rgba(31, 45, 64, 0.8);
  align-items: center;
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 1000;
`;

const Inner = styled.div`
  background-color: ${theme.color("background.dark")};
  max-height: 100%;
  overflow-y: scroll;
  position: relative;

  ${applyStyleProps({
    system: ["margin", "flex-child", "width"],
  })}
`;

export const Overlay = forwardRef(
  ({ onClose, children, ...rest }, forwardedRef) => {
    const ref = useRef();
    useImperativeHandle(forwardedRef, () => ref.current);

    useEffect(() => {
      const handleClickOutside = (event) => {
        if (!ref.current.contains(event.target)) {
          onClose && onClose(event);
        }
      };

      const handleKeydown = (event) => {
        if (event.keyCode === 27) {
          onClose && onClose(event);
        }

        if (event.keyCode === 9) {
          tabLoop(event, ref);
        }
      };

      document.addEventListener("click", handleClickOutside);
      document.addEventListener("keydown", handleKeydown);

      return () => {
        document.removeEventListener("click", handleClickOutside);
        document.removeEventListener("keydown", handleKeydown);
      };
    }, [ref, onClose]);

    return createPortal(
      <OverlayContext.Provider value={{ onClose }}>
        <Wrapper
          as={motion.div}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
        >
          <Inner
            as={motion.div}
            initial="hidden"
            animate="visible"
            variants={containerVariants}
            ref={ref}
            role="dialog"
            aria-modal="true"
            {...rest}
          >
            {children}
            <IconButton
              as={motion.button}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              onClick={onClose}
              title="Click to close purchase overlay"
              $position="absolute"
              $right="0"
              $top="0"
              src={iconClose}
            />
          </Inner>
        </Wrapper>
      </OverlayContext.Provider>,
      document.body
    );
  }
);
