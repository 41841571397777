import styled from "styled-components";
import { applyStyleProps } from "../system";

export const Grid = styled.div`
  display: grid;

  ${applyStyleProps({
  system: ["height", "grid-parent", "grid-child", "margin", "padding", "width"],
})}
`;
