import React from "react";
import styled from "styled-components";

import { applyStyleProps, theme } from "../system";

const Wrapper = styled.button`
  align-items: center;
  background-color: ${theme.color("primary")};
  border: 2px solid ${theme.color("primary")};
  border-radius: 0;
  color: ${theme.color("white")};
  cursor: pointer;
  display: flex;
  height: 52px;
  justify-content: center;
  padding: ${theme.spacing(2)};
  transition: all 0.3s ease-in-out;
  width: 52px;

  svg {
    fill: ${theme.color("white")};
    transition: all 0.3s ease-in-out;
  }

  &:hover,
  &:focus {
    background-color: ${theme.color("primary.dark")};
    border-color: ${theme.color("primary.dark")};
    outline: none;
    text-decoration: none;
  }

  ${applyStyleProps({
    system: ["margin", "width", "position"],
  })}
`;

export const IconButton = ({ src, ...rest }) => (
  <Wrapper {...rest}>
    <svg
      viewBox="0 0 32 32"
      height={24}
      width={24}
      dangerouslySetInnerHTML={{ __html: src }}
    />
  </Wrapper>
);
