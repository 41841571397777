import { createGlobalStyle } from "styled-components";
import { theme } from "../theme";

export const GlobalStyles = createGlobalStyle`
  html {
    box-sizing: border-box;
  }

  *,
  *::before,
  *::after {
    box-sizing: inherit;
  }

  body,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  blockquote,
  pre,
  dl,
  dd,
  ol,
  ul,
  form,
  fieldset,
  legend,
  figure,
  table,
  th,
  td,
  caption,
  hr {
    margin: 0;
    padding: 0;
  }

  li > {
    ul,
    ol {
      margin-bottom: 0;
    }
  }

  table {
    border-collapse: collapse;
    border-spacing: 0;
  }

  body {
    background: ${theme.color("background")};
    color: ${theme.color("text")};
    font-size: ${theme.typography.body.fontSize};
    font-family: "Montserrat", sans-serif;
    font-weight: 400;
    line-height: 1.5;
    min-height: 100vh;
    overflow-y: auto;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
  }

  strong {
    font-weight: 900;
  }
`;
