import React from "react";
import styled, { css } from "styled-components";
import ReactAudioPlayer from "react-audio-player";

import { Container, Flex, IconButton, Text, iconClose } from "../../components";
import { applyStyleProps, theme } from "../../system";

export { PlayerContextProvider } from "./context";

const $isOpen = css`
  transform: translateY(0);
`;

const Wrapper = styled.div`
  align-items: center;
  background-color: ${theme.color("black")};
  bottom: 0;
  color: ${theme.color("text")};
  display: flex;
  justify-content: flex-start;
  margin-top: ${theme.spacing(4)};
  padding: ${theme.spacing(4)};
  position: sticky;
  transform: translateY(100%);
  transition: 0.2s;
  z-index: 20;

  ${applyStyleProps({
    custom: { $isOpen },
  })}
`;

export const Player = ({ heading, sample, handleClose }) => (
  <Wrapper $isOpen={typeof heading !== "undefined"}>
    <Container>
      <Flex $justifyContent="space-between" $alignItems="center">
        <Flex
          $flexDirection={{ xs: "column-reverse", md: "row" }}
          $justifyContent="space-between"
          $alignItems={{ xs: "flex-start", md: "center" }}
        >
          <ReactAudioPlayer src={sample?.url} autoPlay controls volume={0.5} />
          <Text $fontWeight="bold" $marginBottom={0} $marginLeft={3}>
            {heading}
          </Text>
        </Flex>
        <IconButton
          onClick={handleClose}
          title={`Close player and stop playing ${heading}`}
          src={iconClose}
          $display="block"
        />
      </Flex>
    </Container>
  </Wrapper>
);
