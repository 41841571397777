export const iconClose = `
<g stroke="none">
  <path d="M25.1923882,5.39339828 L26.6066017,6.80761184 L17.4143983,15.9993983 L26.6066017,25.1923882 L25.1923882,26.6066017 L15.9993983,17.4143983 L6.80761184,26.6066017 L5.39339828,25.1923882 L14.5853983,15.9993983 L5.39339828,6.80761184 L6.80761184,5.39339828 L15.9993983,14.5853983 L25.1923882,5.39339828 Z" fill="inherit"></path>
</g>
`;

export const iconPlay = `
<g stroke="none">
  <polygon fill="inherit" points="10 5 23 16 10 27"></polygon>
</g>
`;

export const iconPause = `
<g stroke="none">
  <path d="M13,7 L13,25 L9,25 L9,7 L13,7 Z M23,7 L23,25 L19,25 L19,7 L23,7 Z" fill="inherit"></path>
</g>
`;

export const chevronRight = `
<g stroke="none">
  <path d="M11.5479578,6.58578644 C12.2223675,5.86481851 13.2835689,5.80935944 14.017485,6.41940922 L14.1937324,6.58578644 L23,16 L14.1937324,25.4142136 C13.4631219,26.1952621 12.2785683,26.1952621 11.5479578,25.4142136 C10.8735482,24.6932456 10.8216705,23.5587835 11.3923248,22.7742011 L11.5479578,22.5857864 L17.7069797,16 L11.5479578,9.41421356 C10.8735482,8.69324564 10.8216705,7.55878353 11.3923248,6.77420111 L11.5479578,6.58578644 Z" fill="inherit"></path>
</g>
`;

export const chevronLeft = `
<g stroke="none">
<path d="M9.54795784,6.58578644 C10.2223675,5.86481851 11.2835689,5.80935944 12.017485,6.41940922 L12.1937324,6.58578644 L21,16 L12.1937324,25.4142136 C11.4631219,26.1952621 10.2785683,26.1952621 9.54795784,25.4142136 C8.87354819,24.6932456 8.82167052,23.5587835 9.39232485,22.7742011 L9.54795784,22.5857864 L15.7069797,16 L9.54795784,9.41421356 C8.87354819,8.69324564 8.82167052,7.55878353 9.39232485,6.77420111 L9.54795784,6.58578644 Z" fill="inherit" transform="translate(15.000000, 16.000000) scale(-1, 1) translate(-15.000000, -16.000000)"></path>
</g>
`;

export const chevronUp = `
<g stroke="none">
<path d="M11.5479578,6.58578644 C12.2223675,5.86481851 13.2835689,5.80935944 14.017485,6.41940922 L14.1937324,6.58578644 L23,16 L14.1937324,25.4142136 C13.4631219,26.1952621 12.2785683,26.1952621 11.5479578,25.4142136 C10.8735482,24.6932456 10.8216705,23.5587835 11.3923248,22.7742011 L11.5479578,22.5857864 L17.7069797,16 L11.5479578,9.41421356 C10.8735482,8.69324564 10.8216705,7.55878353 11.3923248,6.77420111 L11.5479578,6.58578644 Z" fill="inherit" fill-rule="nonzero" transform="translate(17.000000, 16.000000) rotate(-90.000000) translate(-17.000000, -16.000000) "></path>
</g>
`;
