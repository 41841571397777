import styled from "styled-components";
import { applyStyleProps } from "../system";

export const SampleButton = styled.image`
  background-color: transparent;
  border: 0;
  cursor: pointer;
  outline: 0;

  ${applyStyleProps({
    system: [
      "flex-child",
      "grid-child",
      "margin",
      "object-fit",
      "width",
      "z-index",
    ],
  })}
`;
