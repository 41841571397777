import { css } from "styled-components";
import { getByDotNotation, theme } from ".";

function bgColorTransform(value) {
  if (value === "none") return "transparent";
  const color = getByDotNotation(theme, `colors.${value}`);
  if (color && typeof color === "string") return color;
  if (color && typeof color === "object") {
    const nestedColor = color?.regular;
    if (nestedColor && typeof nestedColor === "string") return nestedColor;
  }
  return null;
}

export const background = {
  $bgColor: (value) => css`
    background-color: ${bgColorTransform(value)};
  `,
};
