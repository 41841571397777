import React from "react";
import Vimeo from "@u-wave/react-vimeo";

import { Box, Button, Flex, Icon, Text, chevronRight } from "../components";

import { Details } from "../patterns";

export const SampleOverlay = ({ title, src, featuring }) => {
  return (
    <>
      <Vimeo video={src} autoplay responsive />
      <Box $padding={5}>
        <Flex $gap={5} $flexDirection="column">
          <Text
            as="h2"
            $fontSize="heading3"
            $fontFamily="heading"
            $fontWeight="900"
            $textTransform="uppercase"
          >
            {title}
          </Text>
          <Details
            underscoreTitle={featuring.heading}
            composer={featuring.composer}
            catalogueNumber={featuring.catalogueNumber}
          />
          <div>
            <Button
              as="a"
              $appearance="secondary"
              href={`/tracks/${featuring.catalogueNumber}`}
            >
              Purchase
              <Icon
                src={chevronRight}
                aria-hidden
                $marginRight={-3}
                $marginLeft={2}
              />
            </Button>
          </div>
        </Flex>
      </Box>
    </>
  );
};
