import styled, { css } from "styled-components";

import { applyStyleProps, theme } from "../system";

const primary = css`
  background-color: ${theme.color("primary")};
  border: 2px solid ${theme.color("primary")};

  &:hover,
  &:focus {
    background-color: ${theme.color("primary.dark")};
    border-color: ${theme.color("primary.dark")};
    outline: none;
    text-decoration: none;
  }
`;

const secondary = css`
  background-color: transparent;
  border: 2px solid ${theme.color("primary")};
  color: ${theme.color("white")};

  &:hover,
  &:focus {
    background-color: ${theme.color("primary.dark")};
    border-color: ${theme.color("primary.dark")};
    outline: none;
    text-decoration: none;
  }
`;

const disabled = css`
  background-color: ${theme.color("grey2")};
  border: 2px solid ${theme.color("grey2")};
  color: ${theme.color("white")};
  cursor: default;
`;

const $appearance = {
  primary,
  secondary,
  disabled,
};

export const Button = styled.button`
  align-items: center;
  border-radius: 0;
  color: ${theme.color("white")};
  cursor: pointer;
  display: inline-flex;
  font-size: ${theme.typography.small.fontSize};
  font-weight: bold;
  justify-content: center;
  overflow: hidden;
  padding: ${theme.spacing(3)} ${theme.spacing(5)};
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  transition: all 0.3s ease-in-out;

  ${applyStyleProps({
    custom: { $appearance },
    system: ["margin", "width", "position"],
  })}
`;

Button.defaultProps = {
  $appearance: "primary",
};
