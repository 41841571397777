import { css } from "styled-components";
import { theme } from ".";

export const flexParent = {
  $gap: (value) => css`
    gap: ${theme.spacing(value)};
  `,
  $flexDirection: (value) => css`
    flex-direction: ${value};
  `,
  $flexWrap: (value) => css`
    flex-wrap: ${value};
  `,
  $justifyContent: (value) => css`
    justify-content: ${value};
  `,
  $alignItems: (value) => css`
    align-items: ${value};
  `,
  $alignContent: (value) => css`
    align-content: ${value};
  `,
};

export const flexChild = {
  $order: (value) => css`
    order: ${value};
  `,
  $flex: (value) => css`
    flex: ${value};
  `,
  $flexGrow: (value) => css`
    flex-grow: ${value};
  `,
  $flexShrink: (value) => css`
    flex-shrink: ${value};
  `,
  $flexBasis: (value) => css`
    flex-basis: ${value};
  `,
  $alignSelf: (value) => css`
    align-self: ${value};
  `,
};
