import React from "react";
import styled, { css } from "styled-components";

import { Flex } from "../../components";
import { applyStyleProps, theme } from "../../system";
import { Content } from "./Content";
import { PlayButton } from "./PlayButton";
import { PurchaseButton } from "./PurchaseButton";

const $selected = css`
  background-color: ${theme.color("black")};
`;

const Wrapper = styled.div`
  background-color: ${theme.color("background.dark")};
  color: ${theme.color("white")};
  padding: ${theme.spacing(2)} ${theme.spacing(4)} ${theme.spacing(4)};
  position: relative;
  transition: 0.25s ease-in-out;

  ${applyStyleProps({
    custom: { $selected },
    system: ["margin"],
  })}
`;

export const SongTile = ({ children: _children, ...rest }) => {
  const children = React.Children.toArray(_children);
  const content = children.find((child) => child.type === Content);
  const playButton = children.find((child) => child.type === PlayButton);
  const purchaseButton = children.find(
    (child) => child.type === PurchaseButton
  );

  return (
    <Wrapper {...rest}>
      {content}

      <Flex $gap={2}>
        {playButton}
        {purchaseButton}
      </Flex>
    </Wrapper>
  );
};

SongTile.Content = Content;
SongTile.Play = PlayButton;
SongTile.Purchase = PurchaseButton;
