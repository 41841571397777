export function getByDotNotation(object, value, preference) {
  if (typeof value === "string") {
    try {
      const index = (obj, i) => obj[i];
      const result = value.split(".").reduce(index, object);

      if (typeof result === "object" && result[preference]) {
        return result[preference];
      }

      if (typeof result === "object" && result.regular) {
        return result.regular;
      }

      return result;
    } catch {
      console.log(`${value} is an invalid key`);
    }
  }
}
