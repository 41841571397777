import styled from "styled-components";
import { applyStyleProps } from "../system";

export const Box = styled.div`
  list-style: none;

  ${applyStyleProps({
  system: [
    "background",
    "border",
    "display",
    "flex-child",
    "flex-parent",
    "height",
    "grid-parent",
    "grid-child",
    "margin",
    "overflow",
    "padding",
    "position",
    "text",
    "width",
  ],
})}
`;
