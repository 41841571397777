import { css } from "styled-components";

export const text = {
  $textAlign: (value) =>
    css`
      text-align: ${value};
    `,
  $textDecoration: (value) =>
    css`
      text-decoration: ${value};
    `,
  $textTransform: (value) =>
    css`
      text-transform: ${value};
    `,
};
