import styled from "styled-components";
import { applyStyleProps } from "../system";

export const Text = styled.span`
  color: inherit;
  font-family: "Montserrat" sans-serif;

  &:hover {
    text-decoration: none;
  }

  ${applyStyleProps({
    system: [
      "color",
      "display",
      "flex-child",
      "font",
      "height",
      "margin",
      "padding",
      "text",
      "width",
    ],
  })}
`;
