function tabLoop(event, ref) {
  if (event && typeof ref === "object") {
    const tabableElements = ref.current.querySelectorAll(
      'a[href], button, textarea, input[type="text"], input[type="radio"], input[type="checkbox"], select'
    );
    const firstElement = tabableElements[0];
    const lastElement = tabableElements[tabableElements.length - 1];

    if (!event.shiftKey && document.activeElement !== firstElement) {
      firstElement.focus();
      return event.preventDefault();
    }

    if (event.shiftKey && document.activeElement !== lastElement) {
      lastElement.focus();
      event.preventDefault();
    }
  }
}

export { tabLoop };
