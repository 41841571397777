import styled, { css } from "styled-components";
import { applyStyleProps } from "../system";

const $size = {
  sm: css`
    max-width: 640px;
  `,
  md: css`
    max-width: 1120px;
  `,
  lg: css`
    max-width: 1400px;
  `,
};

export const Container = styled.div`
  width: 100%;

  ${applyStyleProps({
    system: ["margin", "padding", "position", "width"],
    custom: { $size },
  })}
`;

Container.defaultProps = {
  $size: "lg",
  $marginX: "auto",
};
