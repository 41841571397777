import React from "react";
import Helmet from "react-helmet";
import styled from "styled-components";

import { GlobalStyles } from "../../system";

import { Header } from "./header";
import { Footer } from "./footer";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

const Main = styled.main`
  flex: 1 0 auto;
`;

const siteTitle = "Greenwich Music LTD";
const description =
  "Greenwich Music represents a small exclusive number of exceptional composers and specialises in very high-quality one-off music";

export const Layout = ({ title, children, ...rest }) => (
  <>
    <Helmet
      title={title}
      titleTemplate={`%s · ${siteTitle}`}
      defaultTitle={siteTitle}
      htmlAttributes={{
        lang: "en",
      }}
      meta={[
        {
          name: "description",
          content: description,
        },
        {
          property: `og:title`,
          content: title || siteTitle,
        },
        {
          property: "og:description",
          content: description,
        },
        {
          property: `og:type`,
          content: `website`,
        },
      ]}
    />
    <GlobalStyles />
    <Wrapper {...rest}>
      <Header />
      <Main>{children}</Main>
    </Wrapper>
    <Footer />
  </>
);
