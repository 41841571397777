import { getByDotNotation } from "../utilities";

export const colors = {
  black: "#0d0d0d",
  white: "#D9D9D9",
  grey1: "#D9D9D9",
  grey2: "#BFBFBF",
  grey3: "#404040",
  grey4: "#404040",
  background: {
    dark: "#192433",
    regular: "#1F2D40",
  },
  primary: {
    dark: "#2E4159",
    regular: "#455D73",
    light: "#A2C8D3",
  },
  text: {
    regular: "#D9D9D9",
    dark: "#bfbfbf",
  },
  songs: {
    regular: "#073A67",
    dark: "#BFBFBF",
  },
  themes: {
    regular: "#695D7A",
    dark: "#695000",
  },
  soundtracks: {
    regular: "#3D5D7B",
    dark: "#3D5000",
  },
};

export function color(name, preference) {
  const result = getByDotNotation(colors, name, preference);
  return typeof result === "string" ? result : null;
}
