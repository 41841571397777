import { css } from "styled-components";

export const width = {
  $width: (value) =>
    css`
      width: ${value};
    `,
  $minWidth: (value) =>
    css`
      min-width: ${value};
    `,
  $maxWidth: (value) =>
    css`
      max-width: ${value};
    `,
};
