import { css } from "styled-components";
import { getByDotNotation, theme } from ".";

function colorTransform(value) {
  const color = getByDotNotation(theme, `colors.${value}`);
  if (color && typeof color === "string") return color;
  if (color && typeof color === "object") {
    const nestedColor = color?.regular;
    if (nestedColor && typeof nestedColor === "string") return nestedColor;
  }
  return null;
}

export const color = {
  $color: (value) => css`
    color: ${colorTransform(value)};
  `,
};
