export const typography = {
  heading1: {
    fontSize: "clamp(56px, 5vw, 80px)",
    lineHeight: "1.25em",
  },
  heading2: {
    fontSize: "clamp(40px, 4.55vw, 64px)",
    lineHeight: "1.25em",
  },
  heading3: {
    fontSize: "clamp(32px, 4vw, 44px)",
    lineHeight: "1.125em",
  },
  heading4: {
    fontSize: "clamp(20px, 3vw, 28px)",
    lineHeight: "1.25em",
  },
  body: {
    fontSize: "18px",
    lineHeight: "24px",
  },
  bodySm: {
    fontSize: "16px",
    lineHeight: "20px",
  },
  small: {
    fontSize: "14px",
    lineHeight: "18px",
  },
};

export const fontFamily = {
  body: '"Montserrat", san-serif',
  heading: '"Montserrat", san-serif',
};
