import styled from "styled-components";
import { theme } from "../system";

export const TextInput = styled.input`
  background: ${theme.color("white")};
  border: none;
  cursor: pointer;
  flex: 1 1 auto;
  font-size: ${theme.typography.bodySm.fontSize};
  font-family: "Montserrat" sans-serif;
  font-weight: 300;
  outline: none;
  padding: 0 ${theme.spacing(3)};
`;

TextInput.defaultProps = {
  type: "text",
};
