import { css } from "styled-components";
import { theme } from ".";

function marginValue(value) {
  if (value === "auto") {
    return value;
  } else {
    return theme.spacing(value);
  }
}

export const margin = {
  $margin: (value) =>
    css`
      margin: ${marginValue(value)};
    `,
  $marginTop: (value) =>
    css`
      margin-top: ${marginValue(value)};
    `,
  $marginBottom: (value) =>
    css`
      margin-bottom: ${marginValue(value)};
    `,
  $marginLeft: (value) =>
    css`
      margin-left: ${marginValue(value)};
    `,
  $marginRight: (value) =>
    css`
      margin-right: ${marginValue(value)};
    `,
  $marginX: (value) =>
    css`
      margin-left: ${marginValue(value)};
      margin-right: ${marginValue(value)};
    `,
  $marginY: (value) =>
    css`
      margin-top: ${marginValue(value)};
      margin-bottom: ${marginValue(value)};
    `,
};
