import React from "react";
import { Button, Icon, chevronRight } from "../../components";

export const PurchaseButton = ({ children, ...rest }) => (
  <Button {...rest}>
    {children}
    <Icon src={chevronRight} $size="small" $marginLeft={2} $marginRight={-3} />
  </Button>
);

PurchaseButton.defaultProps = {
  as: "a",
  $appearance: "secondary",
};
