import React from "react";
import styled from "styled-components";
import { applyStyleProps, theme } from "../system";

const Wrapper = styled.ul`
  color: ${theme.color("text")};
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;

  ${applyStyleProps({
    system: ["margin", "flex-parent", "font", "text"],
  })}
`;

const Item = styled.li`
  font-weight: bold;
  margin: 0;
  padding: 0;
`;

const Name = styled.span`
  font-weight: 400;
`;

export const Details = ({
  underscoreTitle,
  composer,
  publisher,
  catalogueNumber,
  ...rest
}) => (
  <Wrapper {...rest}>
    {underscoreTitle && (
      <Item>
        <Name>Underscore title - </Name> {underscoreTitle}
      </Item>
    )}

    {composer && (
      <Item>
        <Name>Composed by - </Name> {composer}
      </Item>
    )}

    {publisher && (
      <Item>
        <Name>Published by - </Name> {publisher}
      </Item>
    )}

    {catalogueNumber && (
      <Item>
        <Name>Catalogue # - </Name> {catalogueNumber}
      </Item>
    )}
  </Wrapper>
);

Details.defaultProps = {
  $fontSize: "small",
  $flexDirection: "column",
  $gap: 1,
};
