import { breakpoints } from "./breakpoints";
import { colors, color } from "./colors";
import { spacing } from "./spacing";
import { typography, fontFamily } from "./typography";

export const theme = {
  breakpoints,
  colors,
  color,
  fontFamily,
  spacing,
  typography,
};
