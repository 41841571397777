import React from "react";
import styled from "styled-components";

import { theme } from "../../../system";
import { Flex, Image, Text } from "../../../components";

const Wrapper = styled.footer`
  background-color: ${theme.color("black")};
  color: ${theme.color("text", "dark")};
  display: block;
  padding: ${theme.spacing(6)};
  text-align: center;
  position: relative;
  z-index: 100;
`;

export const Footer = ({ className, ...rest }) => (
  <Wrapper {...rest}>
    <Flex
      $justifyContent="center"
      $alignItems="center"
      $gap={4}
      $marginBottom={5}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="58"
        width="80"
        viewBox="0 0 200 147"
      >
        <title>PRS for Music</title>
        <g fillRule="evenodd" fill="none">
          <path
            fill="#E80033"
            d="M4.13 71.2c10.4 28.8 36.9 83.8 76.5 74.8 16.4-3 27.4-17 39.4-27 14-11 28-18 43-27.5 0 0 55-28.5 31-57.4 0 0-19-26.3-88-33.1-61.4-6.01-123 22.4-102 70.2"
          ></path>
          <path
            fill="#FEFEFE"
            d="M50.6 49.2h-8.9V36.1h7.7c5.7 0 8.5 2.6 8.5 6.6 0 4.1-3.1 6.5-7.3 6.5m1.1-20.3H34.9c-.8 0-1.5.6-1.5 1.4V71.1c0 .8.7 1.5 1.5 1.5H40.3c.8 0 1.4-.7 1.4-1.5V56.5H49.6c4.1-.1 8.2-1.5 8.2-1.5 2.1-.8 8.7-3.1 8.7-12.9 0-7.7-5.5-13.2-14.8-13.2M88.8 49.2h-8.9V36.1h7.7c5.7 0 8.4 2.6 8.4 6.6 0 4.1-3 6.5-7.2 6.5M105 70.4L95.9 55c2.1-.8 9.1-3.1 9.1-12.9 0-7.7-5.9-13.2-15.1-13.2H73.1c-.8 0-1.4.6-1.4 1.4V71.1c0 .8.6 1.4 1.4 1.5H78.5c.8 0 1.4-.7 1.4-1.5V56.5h7.8l8.1 15.3c.2.4.7.7 1.2.8h6c1 0 2-.7 2-1.5v-.7M133 47l-5-1.2c-5-1.1-9-2-9-5.6 0-3 3-4.8 8-4.8s9 1.8 11 5c0 .4 0 .6 1 .6 0 0 1-.1 1-.3l4-3.3c1-.2 1-.6 1-1.1 0-.4 0-.7-1-.9v-.1c-4-5.1-10-7.5-17-7.5-8 0-17 4.4-17 13.6 0 8 6 10.6 13 12.2l5 1.1c6 1.4 9 2.3 9 5.8 0 4.2-5 5.6-9 5.6-5 0-9-1.8-12-5.6-1-.3-1-.5-2-.5v.3l-5 3.3c0 .3-1 .7-1 1.2 0 .3 0 .6 1 .8v.1c4 5.3 11 8 18 8 9 0 19-3.7 19-14.4 0-8.3-6-10.9-13-12.3"
          ></path>
          <path
            fill="#FCB5C5"
            d="M46 79.6c-.6-.4-1.2-.6-2-.6-1.4 0-2.5.9-3 3.1l-.3 1.6h3.4l-.9 2.1h-2.8c-1.1 6.7-2 15.2-6.9 19.2l-1.6-2c3.9-3.6 4.6-9.1 5.9-17.2h-2l.3-2.1h2l.3-1.8c.8-4.1 3.5-5.2 5.6-5.2 1.1 0 2.1.3 3.1.8L46 79.6zM54 86.7c-.5-.5-1.2-.8-2.2-.8-1.2 0-2.2.5-3 1.4-1.1 1.2-1.8 3.1-1.8 5.1 0 1.3.4 2.3 1.1 3 .5.4 1.2.7 2.1.7 1 0 1.9-.4 2.6-1.1 1.3-1.2 2.1-3.3 2.1-5.4 0-1.2-.3-2.2-.9-2.9m.8 9.6c-1.3 1.3-3 2-4.8 2s-3.1-.6-4-1.4c-1.1-1.1-1.6-2.8-1.6-4.5 0-2.6 1-5 2.6-6.6 1.2-1.3 3-2.1 5-2.1 1.8 0 3.1.6 4 1.5 1 1.1 1.5 2.6 1.5 4.3 0 2.8-1 5.3-2.7 6.8M68.8 86.6c-.4-.2-.9-.3-1.3-.3-1.5 0-3.1 1.5-4.3 6.7l-1 5h-2.6l3-13.9h2l-.4 2.8c1-2 2.2-3.2 4-3.2.8 0 1.4.3 1.8.6l-1.2 2.3z"
          ></path>
          <path
            fill="#FEFEFE"
            d="M89.9 96.8V82.6c0-.4-.4-.7-.8-.7h-1.8c-.3 0-.5.1-.6.3l-3.6 5.5c-.5.7-.8 1.3-1.1 1.8-.3-.5-.7-1.1-1.1-1.7L77 82.2c-.1-.2-.4-.3-.7-.3h-1.8c-.4 0-.8.3-.8.7v14.2c0 .5.4.8.8.8h1.7c.4 0 .8-.3.8-.8v-9.6c.3.6.7 1.1 1 1.6l3 4.2c.2.2.4.3.6.3h.7c.3 0 .5-.1.7-.3l2.7-4.1c.2-.4.8-1.2.9-1.7h.1c-.1.6-.1 1.1-.1 1.7v7.9c0 .4.4.8.8.8h1.7c.5 0 .8-.3.8-.8M107 82.6c0-.4 0-.7-1-.7h-2v9.8c0 1-1 1.8-1 2.4-1 .7-2 1-3 1-.9 0-1.9-.3-2.5-1-.5-.6-.9-1.4-.9-2.6v-8.9c0-.4-.3-.7-.7-.7h-1.7c-.5 0-.8.3-.8.7v9.2c0 1.8.5 3.4 1.5 4.4 1.2 1.1 2.9 1.6 5.1 1.6 2 0 4-.6 5-1.8 1-1.1 2-2.6 2-4.5v-8.9zM113 85.8c0-.9 1-1.5 2-1.5s2 .3 3 .7c0 0 1-.3 1-.7V83.1c0-.3 0-.6-1-.7-1-.4-2-.7-3-.7-4 0-6 2.1-6 4.5 0 1.2 1 2.1 1 2.8 2 2.2 6 2.5 6 4.6 0 1.2-1 1.6-2 1.6s-2-.3-3-.8c0-.1 0-.1-1-.1v.4l-1 1v.4c0 .2 0 .5 1 .6 1 .6 2 1.1 4 1.1 3 0 6-1.8 6-4.6 0-4.9-7-4.7-7-7.4M126 82.7v-.1c0-.4 0-.7-1-.7h-2V97.6h2c1 0 1-.3 1-.8V82.7zM142 95.9v-1.2c-1-.3-1-.5-1-.5v.1c-1 .5-2 .7-4 .7-3 0-5-2.3-5-5.3 0-2.8 2-5.3 6-5.3 1 0 2 .1 3 .6 0 0 1-.3 1-.7V83c0-.3-1-.6-1-.7-1-.4-2-.6-3-.6-6 0-9 3.7-9 8.2 0 4.4 3 7.9 8 7.9 1 0 3-.3 5-1.2v-.7"
          ></path>
        </g>
      </svg>
      <Image src="/logos/PM-logo-96-trans.png" $width="80px" />
    </Flex>
    <Text as="p" $fontSize="small" $marginBottom={2}>
      Contact:{" "}
      <Text
        as="a"
        $fontSize="small"
        $fontWeight="bold"
        href="mailto:info@greenwichmusic.co.uk"
      >
        info@greenwichmusic.co.uk
      </Text>
    </Text>

    <Text as="small" $fontSize="small" $textTransform="uppercase">
      &#169; <Text $fontWeight="bold">Greenwich Music</Text> LTD
    </Text>
  </Wrapper>
);
