import styled from "styled-components";
import { applyStyleProps, theme } from "../system";

export const TextBlock = styled.div`
  padding-bottom: ${theme.spacing(4)};
  position: relative;

  &::after {
    background-color: rgba(255, 255, 255, 0.5);
    border-radius: 2px;
    content: "";
    display: block;
    height: ${theme.spacing(1)};
    left: 50%;
    position: absolute;
    top: 100%;
    transform: translateX(-50%);
    width: ${theme.spacing(7)};
  }

  ${applyStyleProps({
  system: ["margin", "text", "width"],
})}
`;

TextBlock.defaultProps = {
  $marginX: "auto",
  $marginBottom: 8,
  $maxWidth: "640px",
};
