import { css } from "styled-components";
import { theme } from ".";

export const gridParent = {
  $gap: (value) => css`
    gap: ${theme.spacing(value)};
  `,
  $gridTemplateAreas: (value) => css`
    grid-template-areas: ${value};
  `,
  $gridTemplateColumns: (value) => css`
    grid-template-columns: ${value};
  `,
  $gridTemplateRows: (value) => css`
    grid-template-rows: ${value};
  `,
  $justifyContent: (value) => css`
    justify-content: ${value};
  `,
  $justifyItems: (value) => css`
    justify-items: ${value};
  `,
  $alignContent: (value) => css`
    align-content: ${value};
  `,
  $alignItems: (value) => css`
    align-items: ${value};
  `,
  $gridAutoFlow: (value) => css`
    grid-auto-flow: ${value};
  `,
};

export const gridChild = {
  $gridArea: (value) => css`
    grid-area: ${value};
  `,
  $gridColumn: (value) => css`
    grid-column: ${value};
  `,
  $gridRow: (value) => css`
    grid-row: ${value};
  `,
  $justifySelf: (value) => css`
    justify-self: ${value};
  `,
  $alignSelf: (value) => css`
    align-self: ${value};
  `,
};
