import React from "react";
import styled, { css } from "styled-components";
import { Text } from "../components";
import { applyStyleProps, theme } from "../system";

const $counter = (value) => {
  const count = value.toString().padStart(2, "0");
  return css`
    &::before {
      content: "${count}.";
      font-size: 12px;
      font-weight: bold;
      position: absolute;
      top: ${theme.spacing(5)};
    }
  `;
};

const Wrapper = styled.button`
  background-color: ${theme.color("primary")};
  color: ${theme.color("white")};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: ${theme.spacing(7)} ${theme.spacing(5)} ${theme.spacing(5)};
  position: relative;
  transition: 0.15s;
  text-decoration: none;

  &:hover {
    background-color: ${theme.color("primary.dark")};
    box-shadow: 0 ${theme.spacing(3)} ${theme.spacing(4)} rgba(0, 0, 0, 0.15);
  }

  ${applyStyleProps({
    system: ["flex-child"],
    custom: { $counter },
  })}
`;

const Heading = styled.span`
  color: ${theme.color("grey2")};
  font-family: ${theme.fontFamily.heading};
  font-size: clamp(20px, 2.5vw, 40px);
  font-weight: 900;
  line-height: 1;
  margin-bottom: ${theme.spacing(3)};
  text-transform: uppercase;
`;

export const NavTile = ({ heading, subHeading, ...rest }) => (
  <Wrapper {...rest}>
    <Heading>{heading}</Heading>
    {subHeading && <Text>{subHeading}</Text>}
  </Wrapper>
);
