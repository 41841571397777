import { system } from '../.';
import { theme } from '../theme';
import { isFlatObject } from './is-flat-object';

function filterThemeBreakpoints(propBreakpoints) {
  return Object.entries(theme.breakpoints).filter(([key]) =>
    Object.keys(propBreakpoints).includes(key)
  );
}

function resolveValue(propResolver, breakpointValue) {
  if (Array.isArray(propResolver)) {
    return breakpointValue ? propResolver : false;
  } else if (typeof propResolver === 'function') {
    return propResolver(breakpointValue);
  } else if (isFlatObject(propResolver)) {
    return propResolver[breakpointValue];
  } else {
    return false;
  }
}

function buildMediaQuery(currentBreakpointWidth, nextBreakpoint, value) {
  if (nextBreakpoint) {
    const nextBreakpointWidth = nextBreakpoint[1] - 1;
    return [
      `@media (min-width: ${currentBreakpointWidth}px) and (max-width: ${nextBreakpointWidth}px){`,
      ...value,
      '}'
    ];
  } else if (currentBreakpointWidth !== 0) {
    return [`@media (min-width: ${currentBreakpointWidth}px){`, ...value, '}'];
  } else {
    return value;
  }
}

export function applyStyleProps({ custom = {}, system: _system = [] }) {
  return function(props) {
    const systemPropDefinitions = _system.reduce((acc, systemPropDefinition) => {
      return { ...acc, ...system[systemPropDefinition] };
    }, {});

    const definitions = { ...custom, ...systemPropDefinitions };

    const filteredProps = Object.entries(props).filter(([propName, _]) => {
      return Object.keys(definitions).includes(propName);
    });

    const normalisedProps = filteredProps.reduce((acc, [propName, propValue]) => {
      const propBreakpoints = isFlatObject(propValue) ? propValue : { xs: propValue };
      return { ...acc, [propName]: { propBreakpoints, propResolver: definitions[propName] } };
    }, {});

    return Object.entries(normalisedProps).reduce((acc, [_, { propBreakpoints, propResolver }]) => {
      const filteredThemeBreakpoints = filterThemeBreakpoints(propBreakpoints);

      filteredThemeBreakpoints.forEach(([breakpointKey, breakpointWidth], index) => {
        const breakpointValue = propBreakpoints[breakpointKey];
        const nextBreakpoint = filteredThemeBreakpoints[index + 1];
        const value = resolveValue(propResolver, breakpointValue);

        if (value) {
          acc = [...acc, ...buildMediaQuery(breakpointWidth, nextBreakpoint, value)];
        }
      });

      return acc;
    }, []);
  };
}
