import { css } from "styled-components";

export const position = {
  $position: (value) =>
    css`
      position: ${value};
    `,
  $top: (value) =>
    css`
      top: ${value};
    `,
  $bottom: (value) =>
    css`
      bottom: ${value};
    `,
  $right: (value) =>
    css`
      right: ${value};
    `,
  $left: (value) =>
    css`
      left: ${value};
    `,
  $zIndex: (value) =>
    css`
      z-index: ${value};
    `,
};
