import { css } from "styled-components";

export const objectFit = {
  $objectFit: (value) =>
    css`
      object-fit: ${value};
    `,
  $objectPosition: (value) =>
    css`
      object-position: ${value};
    `,
};
