const spacingUnits = [0, 4, 8, 12, 16, 24, 32, 48, 72, 96, 120, 160];

export function spacing(factor) {
  if (typeof factor === "string") {
    return factor;
  }
  const isNegative = factor < 0;
  if (isNegative) factor = factor * -1;
  return `${isNegative ? "-" : ""}${spacingUnits[factor]}px`;
}
