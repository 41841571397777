import { css } from "styled-components";

export const height = {
  $height: (value) => css`
    height: ${value};
  `,
  $minHeight: (value) => css`
    min-height: ${value};
  `,
  $maxHeight: (value) => css`
    max-height: ${value};
  `,
};
