import { css } from "styled-components";
import { getByDotNotation } from "./utilities";
import { theme } from "./theme";

const borderStyle = (value) => {
  if (value === true) {
    return `2px solid ${theme.colors.white}`;
  }
};

function colorTransform(value) {
  if (typeof value === "boolean" || value == null) {
    return null;
  } else {
    return getByDotNotation(theme, `colors.${value}`);
  }
}

export const border = {
  $border: (value) => css`
    border: ${borderStyle(value)};
  `,
  $borderTop: (value) => css`
    border-top: ${borderStyle(value)};
  `,
  $borderBottom: (value) => css`
    border-bottom: ${borderStyle(value)};
  `,
  $borderLeft: (value) => css`
    border-left: ${borderStyle(value)};
  `,
  $borderRight: (value) => css`
    border-right: ${borderStyle(value)};
  `,
  $borderX: (value) => css`
    border-left: ${borderStyle(value)};
    border-right: ${borderStyle(value)};
  `,
  $borderY: (value) => css`
    border-top: ${borderStyle(value)};
    border-bottom: ${borderStyle(value)};
  `,
  $borderColor: (value) => css`
    border-color: ${colorTransform(value)};
  `,
};
